import React, { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import Content from "components/Layout/Content";
import clsx from "clsx";
import classes from "./styles.module.scss";
import { useMediaQuery } from "hooks/useMediaQuery";
import useEmblaCarousel from "embla-carousel-react";

useEmblaCarousel.globalOptions = { loop: true };

function InfoCards({ list, id = "ep-list" }) {
  const [isMobile] = useMediaQuery("(max-width: 660px)");
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "center",
    loop: false,
    autoplay: false,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback(
    index => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);
  if (isMobile) {
    return (
      <div>
        <Content>
          <>
            <div className={classes.embla}>
              <div className={classes.embla__viewport} ref={emblaRef}>
                <div className={classes.embla__container}>
                  {list.map((info, index) => (
                    <div className={classes.embla__slide} key={index}>
                      <Card info={info} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={classes.embla__dots}>
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  selected={index === selectedIndex}
                  onClick={() => scrollTo(index)}
                />
              ))}
            </div>
          </>
        </Content>
      </div>
    );
  }
  return (
    <Content>
      <div className={classes.cardGrid}>
        {list.map((info, index) => {
          return <Card key={index} info={info} />;
        })}
      </div>
    </Content>
  );
}

function Card({ info, ...rest }) {
  return (
    <div className={classes.card}>
      <div>
        <Image alt={info.title} height={75} src={info.image} loading="lazy" />
        <h3 className={classes.title}>{info.title}</h3>
      </div>

      <p className={classes.desc}>{info.description}</p>
    </div>
  );
}
export default InfoCards;

const DotButton = props => {
  const { selected, onClick } = props;

  return (
    <button
      className={clsx(
        classes.embla__dot,
        selected && classes.embla__dot__selected
      )}
      type="button"
      onClick={onClick}
      aria-label="Select slide"
    />
  );
};
