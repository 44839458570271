import clsx from "clsx";
import Content from "components/Layout/Content";
import React from "react";
import classes from "./styles.module.scss";
function About() {
  return (
    <Content>
      <h2 className={classes.heading}>India’s best free online games site</h2>
      <p className={classes.description}>
        Epicplay is one of India’s most popular free online games websites,
        loved by gamers worldwide. With a wide variety of genres like logic and
        brain games, block and building games, fun and casual games, an average
        of 20,000 gamers actively play on a daily basis. The best part is – all
        the games are free to play!
      </p>
      <h3 className={classes.heading}>About us</h3>
      <p className={classes.description}>
        With the rise of the internet, online browser games have become popular
        now more than ever as they are easily accessible. This transition from
        offline to online gaming motivated us to build an effortless platform
        for gaming enthusiasts to enjoy free online games anytime and anywhere.
      </p>
      <h3 className={classes.heading}>Our mission and vision</h3>
      <p className={classes.description}>
        We aim to make Epicplay the first website that comes to a gamer’s mind
        when looking for fun and free games. By hosting a myriad of games for
        free, we try to make entertainment and recreation affordable for
        everyone who loves online games. We strive towards building and
        presenting new and innovative games for every mood, so our players never
        go out of options!
      </p>
      <h3 className={classes.heading}>
        The ultimate online playground for gamers!
      </h3>
      <ul className={clsx(classes.description, classes.list)}>
        <li>5+ game genres</li>
        <li>700K+ monthly players</li>
        <li>250+ daily gaming hours</li>
      </ul>
    </Content>
  );
}

export default About;
