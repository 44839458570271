import React from "react";
import secureImg from "public/icons/secure.png";
import playFavoritesImg from "public/icons/play-favorites.png";
import BestExperienceImg from "public/icons/best-gaming-experience.png";
import InfoCards from "../InfoCards";
const FEATURES = [
  {
    image: playFavoritesImg,
    title: "Play your favorite free games",
    description:
      "Bored at home or at a party? Play some casual games to treat your boredom. Access multiple games with ease and make the most of your free time.",
  },
  {
    image: secureImg,
    title: "World-class security",
    description:
      "We are free from phishing and other types of malware and cyber fraud. We ensure security through SSL certificates and other effective measures.",
  },
  {
    image: BestExperienceImg,
    title: "Best gaming experience",
    description:
      "We keep the user experience as a top priority. Get details about the games, gaming controls, and how to play, and enjoy a smooth gaming experience.",
  },
];
function Features() {
  return <InfoCards id="features-list" list={FEATURES} />;
}

export default Features;
