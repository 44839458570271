import { CATEGORIES, getCategoryKeyFromPath } from "data/categories";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import CategoryTag from "./CategoryTag";
import useScrollable from "hooks/useScrollable";
import FadeTransition from "components/FadeTransition";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";

const CategoryStrip = () => {
  const router = useRouter();
  const categoryPath = router?.query?.category;
  const activeCategory = getCategoryKeyFromPath(categoryPath);

  const [active, setActive] = useState(activeCategory);

  const elementRef = useRef(null);
  const { left, right } = useScrollable(elementRef, {
    left: false,
    right: true,
  });

  useEffect(() => {
    setActive(activeCategory);
  }, [activeCategory]);
  return (
    <div className="relative  my-2 flex w-full flex-1">
      <div
        ref={elementRef}
        className="no-scrollbar relative grid w-full flex-1 snap-x snap-mandatory scroll-ps-2 grid-flow-col grid-rows-1 gap-2 overflow-x-auto rounded px-2"
      >
        {CATEGORIES.map(category => {
          return (
            <CategoryTag
              // onClick={() => {
              //   setActive(category.id);
              // }}
              key={category.id}
              id={category.id}
              category={category}
              active={category.id === active}
            />
          );
        })}
      </div>
      <FadeTransition show={left}>
        <div
          className="absolute inset-y-0 left-0 flex w-10 cursor-pointer items-center justify-center bg-gradient-to-l from-transparent to-primary-base"
          onClick={() => {
            elementRef.current.scrollBy({
              left: -elementRef.current.clientWidth,
              behavior: "smooth",
            });
          }}
        >
          <CgChevronLeft size={24} />
        </div>
      </FadeTransition>

      {/* right */}

      <FadeTransition show={right}>
        <div
          className="absolute inset-y-0 right-0 flex w-10 cursor-pointer items-center justify-center bg-gradient-to-r from-transparent to-primary-base"
          onClick={() => {
            elementRef.current.scrollBy({
              left: elementRef.current.clientWidth,
              behavior: "smooth",
            });
          }}
        >
          <CgChevronRight size={24} />
        </div>
      </FadeTransition>
    </div>
  );
};

export default CategoryStrip;
