import React from "react";
import { GiCheckMark } from "react-icons/gi";
import { ExpandableText } from "components/UI/ExpandableText";
import NoDownloadImg from "public/icons/no-downloading.png";
import NoInstallImg from "public/icons/no-installation.png";
import NoPCImg from "public/icons/no-pc.png";
import Image from "next/image";
import Content from "components/Layout/Content";
import classes from "./styles.module.scss";
import InfoCards from "../InfoCards";
import clsx from "clsx";
const BENEFITS = [
  {
    title: "No downloading required",
    description:
      "All you need to do is go to the gaming website and start playing the game of your choice.",
    image: NoDownloadImg,
  },
  {
    title: "No installation needed",
    description:
      "All the free games on the website are available at your fingertips and are ready to play.",
    image: NoInstallImg,
  },
  {
    title: "No PC space consumed",
    description:
      "Since the games are available online on the browser, you do not need to download anything to your PC.",
    image: NoPCImg,
  },
];
function Benefits() {
  return (
    <>
      <Content>
        <h2 className={classes.title}>
          Play free games online without downloading
        </h2>
        <p className={classes.description}>
          Downloading games, installing them, and signing up with your email IDs
          is a hassle associated with online mobile games that browser games
          overcome. You need nothing but an internet connection to access and
          play online browser games on EpicPlay.
        </p>
        <p className={classes.heading}>
          Check out the three biggest advantages of free online games
        </p>
      </Content>
      <InfoCards id="benefits-list" list={BENEFITS} />
      {/* next section */}
      <Content>
        <h2 className={classes.heading}>Best PC games for everyone</h2>
        <ExpandableText noOfLines={2}>
          <p className={classes.description}>
            PC games or personal computer games are played on computers using
            input devices like a keyboard and mouse, which are used to interact
            with the gameplay. Most PC games do not require an internet
            connection because these kinds of games have been available ever
            since the internet was not as popular or widely available. Browser
            games are sometimes synonymous with PC games but need an internet
            connection.
          </p>
          <div className={classes.card}>
            <h3 className={classes.title}>Why do gamers love PC games?</h3>

            <ul
              className={clsx(
                classes.description,
                classes.list,
                classes.listNoIcon
              )}
            >
              <li>
                PC games are easily accessible, and most of them are free games.
              </li>

              <li>
                Gaming with a keyboard and mouse is a better experience than
                using joysticks.
              </li>
              <li>
                PC games can be easily played from the comfort of your couch.
              </li>
              <li>
                There is a lot of variety in genres - from time-killer games to
                brain games.
              </li>
            </ul>
          </div>
        </ExpandableText>

        {/* next section */}
        <h2 className={classes.heading}>
          Why is epicplay.in the best destination for gamers
        </h2>
        <ExpandableText noOfLines={2}>
          <p className={classes.description}>
            EpicPlay is the one-stop destination for various free online games:
            logic-based games, block games, sports games, and casual games. We
            host some popular and entertaining games like Ludo Matka, Meteor
            Attack, CallBreak, and 2048 that belong to different genres, and
            even continue to add new free games frequently to keep the players
            engaged. We are the best place to entertain yourself by quickly
            tapping into the site in your free time and playing fun games.
          </p>
        </ExpandableText>
        <h2 className={classes.heading}>
          Browser games you can play on desktop or mobile browsers
        </h2>
        <ExpandableText noOfLines={2}>
          <p className={classes.description}>
            Browser games are video games played on web browsers via the
            internet. These games are hosted on websites, are usually free to
            play, and can be single or multiplayer. Some games may or may not be
            compatible with all web browsers, and some may even have a mobile
            app or PC version. Almost all browser games are available on mobile
            browsers. The biggest advantage of browser games for gaming
            enthusiasts is that they need not be installed or downloaded.
          </p>
          <div className={classes.card}>
            <h3 className={classes.title}>
              Three easy steps to play browser games
            </h3>

            <ol className={clsx(classes.description, classes.list)}>
              <li>Open your web browser.</li>
              <li>Go to a gaming website like EpicPlay.</li>
              <li>Start playing games for free.</li>
            </ol>
          </div>
          <p className={classes.heading}>Compatible browsers</p>
          <p className={classes.description}>
            Our games are compatible with almost all browsers, including Google
            Chrome, Safari, Opera, and Mozilla Firefox. Android WebView also
            supports it.
          </p>
        </ExpandableText>
      </Content>
    </>
  );
}

export default Benefits;
