import site from "config/meta";

export const publisher = {
  publisher: {
    '@type': 'Organization',
    name: 'EpicPlay',
    logo: {
      '@type': 'ImageObject',
      creator: {
        '@type': 'Organization',
        name: 'EpicPlay'
      },
      url: site.siteMetadata.logo,
      width: 140,
      height: 24
    }
  }
}

export const organization = ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'EpicPlay',
  alternateName: 'Epic Play',
  description: site.siteMetadata.description,
  url: site.siteMetadata.siteUrl,
  logo: {
    '@type': 'ImageObject',
    creator: {
      '@type': 'Organization',
      name: 'EpicPlay'
    },
    url: site.siteMetadata.logo,
    width: 140,
    height: 24
  },
  image: {
    '@type': 'ImageObject',
    creator: {
      '@type': 'Organization',
      name: 'EpicPlay'
    },
    url: site.siteMetadata.image,
    width: 1200,
    height: 627
  },
  email: 'epicplayofficial.in@gmail.com',
  sameAs: [
    "https://www.epicplay.in",
    "https://www.facebook.com/Epic-Play-100534342864317",
    "https://www.instagram.com/epicplay.in/",
    "https://youtube.com/@epicplay_in",
    "https://twitter.com/epicplay_in"
  ]
});

export const generateProductSchema = data => ({
  '@context': 'https://schema.org/',
  '@type': 'Product',
  name: data?.completeName || 'EpicPlay ' + data.name,
  url: site.siteMetadata.siteUrl + data.path,
  image: {
    '@type': 'ImageObject',
    creator: {
      '@type': 'Organization',
      name: 'EpicPlay'
    },
    url: site.siteMetadata.siteUrl + data.image,
    width: 1200,
    height: 627
  },
  description: data.description,
  brand: {
    '@type': 'Brand',
    name: 'EpicPlay'
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '4.1',
    bestRating: '5',
    worstRating: '1',
    ratingCount: '62'
  }
});

function getHowToStepItem(index, item) {
  return {
    '@type': 'HowToStep',
    name: item?.name || 'Step ' + (index + 1),
    position: index + 1,
    itemListElement: [
      {
        '@type': 'HowToDirection',
        text: item?.text
      }
    ],
    ...(item.image && {
      image: {
        '@type': 'ImageObject',
        creator: {
          '@type': 'Organization',
          name: 'EpicPlay'
        },
        url: site.siteMetadata.siteUrl + item.image,
        width: 638,
        height: 351
      }
    })
  };
}

export const generateHowToSchema = data => {
  return {
    '@type': 'HowTo',
    name: data?.name,
    description: data?.description,
    url: site.siteMetadata.siteUrl + data?.path,
    image: {
      '@type': 'ImageObject',
      creator: {
        '@type': 'Organization',
        name: 'EpicPlay'
      },
      url: site.siteMetadata.siteUrl + data?.image,
      width: 1200,
      height: 627
    },
    step: [...data.items.map((item, index) => getHowToStepItem(index, item))]
  };
};

