import GoogleAdSenseContainer from "adsenseAds/GoogleAdSenseContainer";
import clsx from "clsx";
import AppCard from "components/AppsList/AppCard";
import Content from "components/Layout/Content";
import React from "react";
import { adSenseAdUnits } from "utils/variants";
import classes from "./styles.module.scss";
import HideOnWWW from "components/HideOnWWW";
const homeBtf = adSenseAdUnits.btfHome1;
function GameGrid({ games }) {
  return (
    <Content>
      <div className={classes.epGameGrid}>
        {games.map((item, index) => {
          const myClass = classes["game"];
          const isLarge = (index - 1) % 6 === 0;

          return (
            <div
              // check purpose to see if css and js index is same for isLarge
              // style={{
              //   border: isLarge && "1px solid red",
              // }}
              key={item.game_id}
              className={myClass}
            >
              <AppCard
                item={item}
                // first large card
                priority={isLarge && index < 10 ? true : false}
                index={index}
                sizes={
                  isLarge
                    ? "(max-width: 768px) 60vw, 32vw"
                    : "(max-width: 768px) 20vw, 20vw"
                }
              />
            </div>
          );
        })}
        <div
          className={clsx(classes.rtbAd)}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div id="admixer-hb-1366-638784074715007651"></div>
        </div>
        <div className={clsx(classes.seoContent)}>
          <h1 className="mb-2 mt-4 text-lg font-bold leading-7 text-primary-content md:text-2xl">
            Play Free Online Games On EpicPlay
          </h1>
          <p className="mb-2 text-sm font-light leading-7 text-secondary-content md:text-base">
            EpicPlay is a free online games site offering exciting games at your
            fingertips. All the games are centered around user experience and
            entertainment of the players, and are absolutely free! What’s more?
            Unlike other online and mobile games, they do not require
            downloading and installing.
          </p>
        </div>
        <HideOnWWW>
          <div className={classes.advert}>
            <GoogleAdSenseContainer {...homeBtf} adCheck />
          </div>
        </HideOnWWW>
      </div>
    </Content>
  );
}

export default GameGrid;
