import React, { forwardRef, useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import EPButton from "../EPButton";
import styles from "./styles.module.scss";

export const ExpandableText = forwardRef(
  ({ children, noOfLines, ...rest }, ref) => {
    const [expandedCount, setExpandedCount] = useState(noOfLines);
    const [isClicked, setIsClicked] = useState(false);

    const handleToggle = () => {
      setIsClicked(true);
      setExpandedCount(expandedCount ? undefined : noOfLines);
    };

    const inputRef = React.useRef(null);

    const isTextClamped =
      inputRef.current?.scrollHeight > inputRef.current?.clientHeight ||
      isClicked;

    return (
      <div className={styles.expandableTextContainer} ref={ref}>
        <div
          className={`${styles.expandableText} ${
            isTextClamped ? styles.clamped : ""
          }`}
          style={{ WebkitLineClamp: expandedCount }}
          {...rest}
        >
          {children}
        </div>
        <EPButton
          display={"block"}
          variant="link"
          onClick={handleToggle}
          className={styles.expandButton}
        >
          <div className={styles.expandText}>
            {!expandedCount ? (
              <>
                Read less <MdExpandLess size={16} />
              </>
            ) : (
              <>
                Read more <MdExpandMore size={16} />
              </>
            )}
          </div>
        </EPButton>
      </div>
    );
  }
);

ExpandableText.displayName = "ExpandableText";
