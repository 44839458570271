import { useRouter } from "next/router";
import React from "react";
import { getGamePath, getPlayPath } from "../../../constants/routes.const";
import { PlayIcon } from "../../UI/PlayIcon";
import Image from "next/image";
import { formatNumber } from "utils/number";
import { getClientPageUrl } from "utils/index";
import classes from "./styles.module.scss";
import Link from "next/link";
import dynamic from "next/dynamic";
import { LogService } from "service/LogService";
const AddToFavButton = dynamic(() => import("components/AddToFavButton"), {
  ssr: false,
});
const AppCard = ({ item, index = true, favorite = false, sizes, priority }) => {
  const router = useRouter();
  const clickEventName = router.query?.game
    ? "card_clicked_game_description"
    : "card_clicked_home_page";

  return (
    <div className={classes.container}>
      {favorite && (
        <div className="absolute right-1 top-1 z-20 h-10 w-10 drop-shadow-2xl ">
          <AddToFavButton small={true} game={item} />
        </div>
      )}
      <Image
        src={item.imageUrl}
        priority={priority}
        sizes={sizes}
        alt={item.imageAlt}
        className={classes.image}
      />
      <Link
        href={getGamePath(item.pathname)}
        onClick={() => {
          LogService.logEvent(clickEventName, {
            game: item.game_id,
            url: getClientPageUrl(),
          });
        }}
        aria-label={item.title}
        prefetch={false}
        className={classes.text}
      >
        {item.title}
      </Link>
    </div>
  );
};

export default AppCard;
