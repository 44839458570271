export const LogService = {
  async logEvent(event, data) {
    gtag("event", event, data);

    if (event === "game_loaded") {
      gtag("event", "conversion", {
        send_to: "AW-708372784/bzgNCLmyu5IZELDS49EC",
      });
    }
  },
};
