import clsx from "clsx";
import EPButton from "components/UI/EPButton";
import { getCategoryPath } from "constants/routes.const";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";
import classes from "./style.module.scss";

const CategoryTag = ({ category, active, onClick = () => {} }) => {
  const router = useRouter();
  const ref = useRef();
  const Icon = category.icon;
  useEffect(() => {
    if (active && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [active, router.asPath, category]);
  return (
    <Link
      href={active ? "/" : getCategoryPath(category.pathname)}
      prefetch={false}
    >
      <EPButton
        onClick={onClick}
        ref={ref}
        variant={active ? "primary" : "secondary"}
        key={category.id}
        className={classes.category}
      >
        <Icon alt={category.name} height="16px" width="16px" />
        {category.name}
      </EPButton>
    </Link>
  );
};

export default CategoryTag;
